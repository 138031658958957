import { Link } from "gatsby"
import React from "react"

const Header = ({ itemsA, urlA }) => {
  return (
    <div
      id="header"
      className="fixed w-full flex flex-row justify-center h-auto  pointer-events-none"
      // style={{ maxWidth: "1920px" }}
    >
      <nav className="flex flex-row w-5/6 justify-between content-center pt-xs pointer-events-none">
        <div className="flex pointer-events-auto">
          <svg id="logo" viewBox="0 -1 40 45" overflow="auto" version="1.1">
            <path
              id="logoD"
              stroke="white"
              strokeWidth="0.5"
              fill="none"
              fillOpacity="0.3"
              d="M20,0 L1.81818182,0 L0,0 L0,1.81818182 L0,38.1818182 L0,40 L1.81818182,40 L20,40 C31.0278788,40 40,31.0278788 40,20 C40,8.97212121 31.0278788,0 20,0"
              fillRule="evenodd"
            ></path>
            <circle
              id="logoO"
              stroke="white"
              strokeWidth="0.5"
              fill="none"
              fillRule="evenodd"
              cx="20"
              cy="20"
              r="20"
            />
          </svg>
        </div>
        <ul className="pointer-events-auto flex flex-row list justify-between ">
          {itemsA.map((item, i) => (
            <li key={i.toString()}>
              <div className="text-p  py-4 pl-8">
                <Link
                  to={urlA[i]}
                  style={{
                    boxShadow: "0 -1px 0 0 rgba(255, 255, 255, 0) inset",
                  }}
                  activeStyle={{
                    cursor: "default",
                    opacity: 0.5,
                    boxShadow: "0 -1px 0 0 rgba(255, 255, 255, 1) inset",
                  }}
                >
                  {item}
                </Link>
              </div>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  )
}

export default React.memo(Header)
